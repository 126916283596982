import React, { Component } from "react";

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { body } from 'components/Theme/Copy';
import { Card } from 'components/Theme/Grid';

import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from 'gatsby';
import ThemeButton from 'components/Theme/Button';

const MainCard = styled(Card)`
  position:relative;
  
  ${media.lessThan("tablet")`
    &:last-child {
      display:none;
    }
  `};
`;

const RecipeLink = styled(Link)`
  width:100%;
  height:100%;
  display:block;
  position:relative;
`;

const Asset = styled(GatsbyImage)`
  width:100%;
  border-radius:0.5rem;
`;

const Title = styled(body)`
  font-size:1.4rem;
  color:#fff;
  line-height:2rem;
  text-align:left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin:0.5rem 0;

  ${media.lessThan("tablet")`
    font-size:1.5rem;
  `}
`;

const Diet = styled(body)`
  font-size:1.2rem;
  line-height:1.2rem;
  font-weight:400;
  color:${p => p.theme.brandPrimary};
`;

const Author = styled(body)`
  font-size:1.4rem;
  line-height:3rem;
  font-weight:bold;
  

  width:100%;
  padding:2rem;
  color:${p => p.authorColor == 'Light' ? p.theme.white : p.theme.black};
  position:absolute;
  display:flex;
  align-items: flex-end;
  top:0;
  left:0;
  right:0;
  z-index:1;
  text-align:right;
  overflow:hidden;
`;

const AuthorAvatar = styled(Asset)`
  height:24px;
  width:24px;
  position:relative;
  top:-0.3rem;
  margin:0 0.5rem;
`;

const Details = styled.div`
  background-color:rgba(40, 40, 40, 0.8);
  width:auto;
  padding:1rem 2rem;
  color:${p => p.theme.brandPrimary};
  position:absolute;
  display:flex;
  justify-content: space-between;
  align-items: center;
  bottom:0;
  left:0;
  right:0;
  z-index:1;
  text-transform:uppercase;
  text-align:left;
  letter-spacing:0.2em;
  border-radius:0 0 0.5rem 0.5rem;
  height:10rem;
  overflow:hidden;
`;

const Content = styled.div`

`;

const Action = styled.div`

`;

const Button = styled(ThemeButton)`
  font-size:1.2rem;
  color:#000;
  font-weight:bold;

  ${media.lessThan("desktop")`
    padding:1rem 1.5rem;
  `}

  ${media.lessThan("tablet")`
    padding:2rem 3rem;
  `}
`;

class RecipeCard extends Component {

    render() {
      const { size, sm, neumorphic, hasButton } = this.props;
      const { handle, diet, heroTitle, directionsTotalTime, heroAsset, author, authorColor, authorAvatar } = this.props.data;
      console.log(authorColor)
      return (
        <MainCard neumorphic={neumorphic} size={size} sm={sm}>
          { author && 
            <Author authorColor={authorColor}>
              by <AuthorAvatar image={authorAvatar.gatsbyImageData} /> { author }
            </Author>
          }
          <RecipeLink to={ '/recipes/' + handle }>
            <Details>
              <Content hasButton={hasButton}>
                { diet && 
                  <Diet>{ diet }</Diet>
                }

                <Title>{ heroTitle }</Title>
                { directionsTotalTime }
              </Content>

              <Action>
                { hasButton && <Button to={ '/recipes/' + handle }>READ</Button> }
              </Action>
            </Details>

            <Asset image={heroAsset.gatsbyImageData} />

            
          </RecipeLink>
        </MainCard>
      )
    }
}

export default RecipeCard