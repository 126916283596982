import React from "react"
import { graphql } from 'gatsby';
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import RecipeCard from 'components/Page/Recipe/RecipeCard';

const Container = styled.div`
  text-align:center;
`;

const NotFound = styled.div`
  padding:10rem;
`;

const Heading = styled.div`
  font-size:5rem;
  line-height:4.8rem;
  font-weight:200;
  margin-bottom:2rem;
  color:${p => p.theme.cardTextColor};
  ${media.lessThan("tablet")`
    font-size:3rem;
    line-height:3.2rem;
  `}
`;

const Subheading = styled.div`
  color:${p => p.theme.cardTextColor};
  line-height:2.4rem;
  font-size:2rem;
`;

const NotFoundPage = (props) => {
  
  return (
    <Layout>
      <Container>
        <SEO title="404: Not found" />

        <NotFound>
          <Heading>PAGE NOT FOUND</Heading>
          <Subheading>Try making dough in the meantime! It's easy. Recipe below.</Subheading>
        </NotFound>

      </Container>
    </Layout>
  )
}

export default NotFoundPage
